<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      empty-message="No se encontraron materiales solcitados"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'three-line': true }"
      :loading="loadingData"
      :showing-filter.sync="showingDialogs.filter"
      :showing-sorter.sync="showingDialogs.sorter"
      :load="loadItems"
      :reload-dirty-items="reloadDirtyItems"
      :page-loaded="pageLoaded"
      pk-name="idlsolicitud_material"
    >
      <template
        #listItem="slotProps"
      >
        <lsolicitud-material-list-item
          :item="slotProps.item"
          :index="slotProps.index"
          :has-perm-borrar="hasDeletePerm($route.meta.permission.idobjeto)"
          @click-delete="clickDelete"
        />
      </template>
      <volver-parte-checklist-menu-items
        slot="menuItemsLongPress"
        :idparte-trabajo="routeParams.idparte_trabajo"
      />
    </b10-list>
    <lparte-trabajo-delete
      :showing.sync="showingDialogs.delete"
      :data="deleteItemData"
      :mensaje="deleteMessage"
      @delete="clickDeleteConfirm"
    />
    <b10-fab-button
      @click="clickAdd"
    />
  </b10-base>
</template>

<script>
import { listPageMixin } from '@/mixins/listPageMixin'
import Data from './LsolicitudMaterialListData'
import LsolicitudMaterialListItem from './components/LsolicitudMaterialListItem'
import VolverParteChecklistMenuItems from '../components/VolverParteChecklistMenuItems'
import LparteTrabajoDelete from '../components/LparteTrabajoDelete'
import { get } from 'vuex-pathify'
import { NextRoute } from '@/router'


const pageStoreName = 'pagesOfflineLsolicitudMaterialList'

export default {
  components: {
    LsolicitudMaterialListItem, VolverParteChecklistMenuItems, LparteTrabajoDelete
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      pageStoreName,
      showingDialogs: {
        delete: false,
      },
      deleteItemData: {},
      deleteMessage: `¿Desea eliminar el material de la solicitud?`,
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
  },
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Artículos solicitados'
      this.pageLoaded = true
    },
    async loadItems () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectLsolicitudMaterial(
          this,
          this.filter,
          this.search,
          this.sorter,
          this.currentPage,
          this.routeParams.idsolicitud_material
        )
        await this.loadItemsBase(dataset, metadata)
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        const dataset = await Data.selectLsolicitudMaterialRows(this, dirty.modified)
        await this.reloadItemsBase(dataset, dirty, 'idlsolicitud_material')
      } finally {
        this.loadingData = false
      }
    },
    async clickAdd () {
      const solicitudMaterial = await this.$offline.solicitudMaterial.selectParteDeSolicitud(this.routeParams.idsolicitud_material)
      this.$appRouter.push({
        name: 'offline__articulo-list',
        params: {
          nextRoute: NextRoute(
            'offline__parte-trabajo-solicitud-material__material-add',
            {
              idsolicitud_material: this.routeParams.idsolicitud_material,
              idparte_trabajo: this.routeParams.idparte_trabajo,
              idarticulo: null,
            },
            {}, // query
            true,
          ),
        },
        query: {
          idttarifa: solicitudMaterial.orden_trabajo.idttarifa,
          idbanco_precio: solicitudMaterial.orden_trabajo.idbanco_precio,
        }
      })
    },
    clickDelete (data) {
      this.showingDialogs.delete = !this.showingDialogs.delete
      this.deleteMessage = `¿Desea eliminar el material ${data.item.descripcion} de la solicitud?`
      this.deleteItemData = data
    },
    async clickDeleteConfirm (data) {
      this.$loading.showManual('Eliminando...')
      try {
        await this.$offline.lsolicitudMaterial.deleteUpdateLordenTrabajo(data.item.idlsolicitud_material, data.deleteOT)
        await this.dispatchStore('deleteItem', data.index)
      } finally {
        this.$loading.hide()
      }
    },
  },
}
</script>
