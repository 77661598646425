export default {
  _selectLsolicitudMaterial (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.lsolicitud_material)
  },
  async selectLsolicitudMaterial (Vue, filter, search, sorter, page, idsolicitudMaterial) {
    const tables = Vue.$offline.db.tables
    let where = []
    let whereSearch = []
    let whereFilter = []
    where.push(
      Vue.$offline.db.op.and(
        tables.lsolicitud_material.idsolicitud_material.eq(idsolicitudMaterial),
        tables.lsolicitud_material.estado.gt(0),
        ...whereSearch,
        ...whereFilter
      )
    )
    return [
      await this._selectLsolicitudMaterial(Vue)
        .where(...where)
        .limit(Vue.$offline.db.ITEMS_PER_PAGE)
        .skip(page * Vue.$offline.db.ITEMS_PER_PAGE)
        .exec(),
      []
    ]
  },
  async selectLsolicitudMaterialRows (Vue, pks) {
    const tables = Vue.$offline.db.tables
    return await this._selectLsolicitudMaterial(Vue)
      .where(tables.lsolicitud_material.idlsolicitud_material.in(pks))
      .exec()
  },
  async deleteLsolicitudMaterial (Vue, idlsolicitudMaterial) {
    const tables = Vue.$offline.db.tables
    const detalleSolicitudMaterial = await Vue.$offline.db
      .select()
      .from(tables.llsolicitud_material)
      .where(
        Vue.$offline.db.op.and(
          tables.llsolicitud_material.idlsolicitud_material.eq(idlsolicitudMaterial)
        )
      )
      .exec()
    for (const ll of detalleSolicitudMaterial) {
      await Vue.$offline.llsolicitudMaterial.deleteSync(ll.idllsolicitud_material)
    }
    await Vue.$offline.lsolicitudMaterial.deleteSync(idlsolicitudMaterial)
  }
}
